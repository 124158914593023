/* eslint-disable prettier/prettier */
import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import BlogLayout from './components/blog/BlogLayout';
// import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
import DocsLayout from './components/docs/DocsLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import { featureFlagConfig } from './config';

// import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Browse pages

// const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
// const BrowseButtons = Loadable(lazy(() => import('./pages/browse/BrowseButtons')));
// const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')));
// const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')));
// const BrowseDetailLists = Loadable(lazy(() => import('./pages/browse/BrowseDetailLists')));
// const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
// const BrowseGridLists = Loadable(lazy(() => import('./pages/browse/BrowseGridLists')));
// const BrowseGroupedLists = Loadable(lazy(() => import('./pages/browse/BrowseGroupedLists')));
// const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')));
// const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')));
// const BrowseQuickStats = Loadable(lazy(() => import('./pages/browse/BrowseQuickStats')));
// const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')));
// const BrowseTypography = Loadable(lazy(() => import('./pages/browse/BrowseTypography')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordChange = Loadable(lazy(() => import('./pages/authentication/PasswordChange')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Blog pages

const BlogPostCreate = Loadable(lazy(() => import('./pages/blog/BlogPostCreate')));
const BlogPostDetails = Loadable(lazy(() => import('./pages/blog/BlogPostDetails')));
const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));

const OrderList = Loadable(lazy(() => import('./pages/orders-v2/list')));
const OrderCreate = Loadable(lazy(() => import('./pages/orders-v2/create')));
const OrderDetail = Loadable(lazy(() => import('./pages/orders-v2/detail/index')));
const OrderEdit = Loadable(lazy(() => import('./pages/orders-v2/edit')));
const JobsList = Loadable(lazy(() => import('./pages/job/List')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const Report = Loadable(lazy(() => import('./pages/dashboard/Report')));

// User pages

const UserDetails = Loadable(lazy(() => import('./pages/user/UserDetails')));
const UserEdit = Loadable(lazy(() => import('./pages/user/UserEdit')));
const UserCreate = Loadable(lazy(() => import('./pages/user/UserCreate')));
const UserList = Loadable(lazy(() => import('./pages/user/UserList')));
const UserCreateKPI = Loadable(lazy(() => import('./pages/user/UserCreateKpi')));
const UserEditKPI = Loadable(lazy(() => import('./pages/user/UserEditKpi')));

// Docs pages

const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));

// Social pages

const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

// Other pages

const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Contact = Loadable(lazy(() => import('./pages/Contact')));
// const Home = Loadable(lazy(() => import('./pages/Home')));
const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

// Mega Category
const MegaCateList = Loadable(lazy(() => import('./pages/megaCategory/List')));
const MegaCateCreate = Loadable(lazy(() => import('./pages/megaCategory/Create')));
const MegaCateEdit = Loadable(lazy(() => import('./pages/megaCategory/Edit')));

// Mega Category
const SubCateList = Loadable(lazy(() => import('./pages/sub-category/list')));
const SubCateCreate = Loadable(lazy(() => import('./pages/sub-category/create')));
const SubCateDetail = Loadable(lazy(() => import('./pages/sub-category/detail')));
// Category
const CateList = Loadable(lazy(() => import('./pages/category/List')));
const CateCreate = Loadable(lazy(() => import('./pages/category/Create')));
const CateEdit = Loadable(lazy(() => import('./pages/category/Edit')));

// Advertiser
const AdsList = Loadable(lazy(() => import('./pages/advertiser/List')));
const AdsCreate = Loadable(lazy(() => import('./pages/advertiser/Create')));
const AdsEdit = Loadable(lazy(() => import('./pages/advertiser/Edit')));

// Product
const ProductCreate = Loadable(lazy(() => import('./pages/product/create')));
const ProductList = Loadable(lazy(() => import('./pages/product/list')));
const ProductEdit = Loadable(lazy(() => import('./pages/product/detail')));

// Product V2
const ProductV2Create = Loadable(lazy(() => import('./pages/products-v2/create')));
const ProductV2List = Loadable(lazy(() => import('./pages/products-v2/list')));
const ProductV2Edit = Loadable(lazy(() => import('./pages/products-v2/detail')));

// Customers
const CustomerList = Loadable(lazy(() => import('./pages/customers/list')));
const CustomerCreate = Loadable(lazy(() => import('./pages/customers/create')));
const CustomerEdit = Loadable(lazy(() => import('./pages/customers/edit')));
const CustomerDetail = Loadable(lazy(() => import('./pages/customers/detail')));

// WarehouseFloor
const WarehouseFloorList = Loadable(lazy(() => import('./pages/warehouse-floor/list')));
const WarehouseFloorCreate = Loadable(lazy(() => import('./pages/warehouse-floor/create')));
const WarehouseFloorEdit = Loadable(lazy(() => import('./pages/warehouse-floor/edit')));
const WarehouseFloorDetail = Loadable(lazy(() => import('./pages/warehouse-floor/detail')));

// WarehousePalette
const WarehousePaletteByFloorCreate = Loadable(lazy(() => import('./pages/warehouse-palette/create')));
const WarehousePaletteByFloorDetail = Loadable(lazy(() => import('./pages/warehouse-palette/detail')));
const WarehousePaletteByFloorEdit = Loadable(lazy(() => import('./pages/warehouse-palette/edit')));

// Tag
const TagList = Loadable(lazy(() => import('./pages/tag/List')));
const TagCreate = Loadable(lazy(() => import('./pages/tag/Create')));
const TagEdit = Loadable(lazy(() => import('./pages/tag/Edit')));

// Sale
const SaleList = Loadable(lazy(() => import('./pages/sale/List')));
const SaleCreate = Loadable(lazy(() => import('./pages/sale/Create')));
const SaleEdit = Loadable(lazy(() => import('./pages/sale/Edit')));

// Recipe
const RecipeList = Loadable(lazy(() => import('./pages/recipe/List')));
const RecipeCreate = Loadable(lazy(() => import('./pages/recipe/Create')));
const RecipeEdit = Loadable(lazy(() => import('./pages/recipe/Edit')));

// Postcode
const PostcodeList = Loadable(lazy(() => import('./pages/postcode/List')));
const PostcodeEdit = Loadable(lazy(() => import('./pages/postcode/Edit')));
const PostcodeCreate = Loadable(lazy(() => import('./pages/postcode/Create')));

// Store
const StoreList = Loadable(lazy(() => import('./pages/store/List')));
const StoreCreate = Loadable(lazy(() => import('./pages/store/Create')));
const StoreEdit = Loadable(lazy(() => import('./pages/store/Edit')));

// Brand
const BrandList = Loadable(lazy(() => import('./pages/brand/List')));
const BrandCreate = Loadable(lazy(() => import('./pages/brand/Create')));
const BrandEdit = Loadable(lazy(() => import('./pages/brand/Edit')));

// Brand Category
const BrandCateList = Loadable(lazy(() => import('./pages/brandCategory/List')));
const BrandCateCreate = Loadable(lazy(() => import('./pages/brandCategory/Create')));
const BrandCateEdit = Loadable(lazy(() => import('./pages/brandCategory/Edit')));

// Catalog
const CatalogList = Loadable(lazy(() => import('./pages/catalog/List')));
const CatalogCreate = Loadable(lazy(() => import('./pages/catalog/Create')));
const CatalogEdit = Loadable(lazy(() => import('./pages/catalog/Edit')));

// Supplier
const SupplierList = Loadable(lazy(() => import('./pages/supplier/list')));
const SupplierCreate = Loadable(lazy(() => import('./pages/supplier/create')));
const SupplierEdit = Loadable(lazy(() => import('./pages/supplier/edit')));
const SupplierDetail = Loadable(lazy(() => import('./pages/supplier/detail')));

// PackagingUnit
const PackagingUnitList = Loadable(lazy(() => import('./pages/packaging-units/list')));
const PackagingUnitCreate = Loadable(lazy(() => import('./pages/packaging-units/create')));
const PackagingUnitEdit = Loadable(lazy(() => import('./pages/packaging-units/edit')));

export const demoRoutes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'password-change',
        element: <PasswordChange />,
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: 'blog',
    element: <BlogLayout />,
    children: [
      {
        path: '',
        element: <BlogPostList />,
      },
      {
        path: 'new',
        element: <BlogPostCreate />,
      },
      {
        path: ':postId',
        element: <BlogPostDetails />,
      },
    ],
  },
  {
    path: 'mega-category',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <MegaCateList />,
      },
      {
        path: 'new',
        element: <MegaCateCreate />,
      },
      {
        path: ':megaId/edit',
        element: <MegaCateEdit />,
      },
    ],
  },
  {
    path: 'brand',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <BrandList />,
      },
      {
        path: 'new',
        element: <BrandCreate />,
      },
      {
        path: ':brandId/edit',
        element: <BrandEdit />,
      },
    ],
  },
  {
    path: 'catalog',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <CatalogList />,
      },
      {
        path: 'new/:catalogs',
        element: <CatalogCreate />,
      },
      {
        path: ':catalogId/edit',
        element: <CatalogEdit />,
      },
    ],
  },
  {
    path: 'supplier',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <SupplierList />,
      },
      {
        path: 'new',
        element: <SupplierCreate />,
      },
      {
        path: ':supplierId/edit',
        element: <SupplierEdit />,
      },
      {
        path: ':supplierId/detail',
        element: <SupplierDetail />,
      },
    ],
  },
  {
    path: 'packaging-unit',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <PackagingUnitList />,
      },
      {
        path: 'new',
        element: <PackagingUnitCreate />,
      },
      {
        path: ':packagingUnitId/edit',
        element: <PackagingUnitEdit />,
      },
    ],
  },
  {
    path: 'store',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <StoreList />,
      },
      {
        path: 'new',
        element: <StoreCreate />,
      },
      {
        path: ':storeId/edit',
        element: <StoreEdit />,
      },
    ],
  },
  {
    path: 'category',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <CateList />,
      },
      {
        path: 'new',
        element: <CateCreate />,
      },
      {
        path: ':cateId/edit',
        element: <CateEdit />,
      },
    ],
  },
  {
    path: 'brand-category',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <BrandCateList />,
      },
      {
        path: 'new',
        element: <BrandCateCreate />,
      },
      {
        path: ':brandCateId/edit',
        element: <BrandCateEdit />,
      },
    ],
  },
  {
    path: 'sub-category',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <SubCateList />,
      },
      {
        path: 'new',
        element: <SubCateCreate />,
      },
      {
        path: ':id/edit',
        element: <SubCateDetail />,
      },
    ],
  },
  {
    path: 'advertiser',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <AdsList />,
      },
      {
        path: 'new',
        element: <AdsCreate />,
      },
      {
        path: ':adsId/edit',
        element: <AdsEdit />,
      },
    ],
  },
  {
    path: 'tag',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <TagList />,
      },
      {
        path: 'new',
        element: <TagCreate />,
      },
      {
        path: ':tagId/edit',
        element: <TagEdit />,
      },
    ],
  },
  {
    path: 'products',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: featureFlagConfig.productsV2 ? <ProductV2List /> : <ProductList />,
      },
      {
        path: 'new',
        element: featureFlagConfig.productsV2 ? <ProductV2Create /> : <ProductCreate />,
      },
      {
        path: ':id/edit',
        element: featureFlagConfig.productsV2 ? <ProductV2Edit /> : <ProductEdit />,
      },
    ],
  },
  {
    path: 'warehouse-floor',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <WarehouseFloorList />,
      },
      {
        path: ':warehouseFloorId/detail',
        element: <WarehouseFloorDetail />,
      },
      {
        path: ':warehouseFloorId/edit',
        element: <WarehouseFloorEdit />,
      },
      {
        path: 'new',
        element: <WarehouseFloorCreate />,
      },
      {
        path: ':warehouseFloorId/warehouse-palette/new',
        element: <WarehousePaletteByFloorCreate />,
      },
      {
        path: ':warehouseFloorId/warehouse-palette/:paletteId/detail',
        element: <WarehousePaletteByFloorDetail />,
      },
      {
        path: ':warehouseFloorId/warehouse-palette/:paletteId/edit',
        element: <WarehousePaletteByFloorEdit />,
      },
    ],
  },
  {
    path: 'sale',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <SaleList />,
      },
      {
        path: 'new',
        element: <SaleCreate />,
      },
      {
        path: ':saleId/edit',
        element: <SaleEdit />,
      },
    ],
  },
  {
    path: 'recipe',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <RecipeList />,
      },
      {
        path: 'new',
        element: <RecipeCreate />,
      },
      {
        path: ':recipeId/edit',
        element: <RecipeEdit />,
      },
    ],
  },
  {
    path: 'postcode',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <PostcodeList />,
      },
      {
        path: ':postcodeId/edit',
        element: <PostcodeEdit />,
      },
      {
        path: 'create',
        element: <PostcodeCreate />,
      },
    ],
  },
  {
    path: 'contact',
    element: <Contact />,
  },
  {
    path: 'orders',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <OrderList />,
      },
      {
        path: 'new',
        element: <OrderCreate />,
      },
      {
        path: ':orderId/detail',
        element: <OrderDetail />,
      },
      {
        path: ':orderId/edit',
        element: <OrderEdit />,
      },
    ],
  },
  {
    path: 'jobs',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <JobsList />,
      },
    ],
  },
  {
    path: 'management',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'users',
        children: [
          {
            path: '',
            element: <UserList />,
          },
          {
            path: ':userId/detail',
            element: <UserDetails />,
          },
          {
            path: ':userId/edit',
            element: <UserEdit />,
          },
          {
            path: 'new',
            element: <UserCreate />,
          },
          {
            path: ':userId/new-kpi',
            element: <UserCreateKPI />,
          },
          {
            path: ':userId/kpi/:kpiId',
            element: <UserEditKPI />,
          },
        ],
      },
      {
        path: 'customers',
        children: [
          {
            path: '',
            element: <CustomerList />,
          },
          {
            path: ':customerId/detail',
            element: <CustomerDetail />,
          },
          {
            path: ':customerId/edit',
            element: <CustomerEdit />,
          },
          {
            path: 'new',
            element: <CustomerCreate />,
          },
        ],
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
      {
        path: 'report',
        element: <Report />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'analytics',
        element: <Analytics />,
      },
      {
        path: 'calendar',
        element: <Calendar />,
      },
      {
        path: 'chat',
        children: [
          {
            path: '',
            element: <Chat />,
          },
          {
            path: 'new',
            element: <Chat />,
          },
          {
            path: ':threadKey',
            element: <Chat />,
          },
        ],
      },
      {
        path: 'customers',
        children: [
          {
            path: '',
            element: <UserList />,
          },
          {
            path: ':customerId',
            element: <UserDetails />,
          },
          {
            path: ':customerId/edit',
            element: <UserEdit />,
          },
        ],
      },
      {
        path: 'invoices',
        children: [
          {
            path: '',
            element: <InvoiceList />,
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetails />,
          },
        ],
      },
      {
        path: 'kanban',
        element: <Kanban />,
      },
      {
        path: 'mail',
        children: [
          {
            path: '',
            element: <Navigate to="/dashboard/mail/all" replace />,
          },
          {
            path: 'label/:customLabel',
            element: <Mail />,
          },
          {
            path: 'label/:customLabel/:emailId',
            element: <Mail />,
          },
          {
            path: ':systemLabel',
            element: <Mail />,
          },
          {
            path: ':systemLabel/:emailId',
            element: <Mail />,
          },
        ],
      },
      {
        path: 'finance',
        element: <Finance />,
      },

      {
        path: 'projects',
        children: [
          {
            path: 'browse',
            element: <ProjectBrowse />,
          },
          {
            path: 'new',
            element: <ProjectCreate />,
          },
          {
            path: ':projectId',
            element: <ProjectDetails />,
          },
        ],
      },
      {
        path: 'social',
        children: [
          {
            path: 'feed',
            element: <SocialFeed />,
          },
          {
            path: 'profile',
            element: <SocialProfile />,
          },
        ],
      },
    ],
  },
  {
    path: 'docs',
    element: <DocsLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/docs/overview/welcome" replace />,
      },
      {
        path: '*',
        element: <Docs />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
      {
        path: 'checkout',
        element: <Checkout />,
      },
      {
        path: 'pricing',
        element: <Pricing />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default demoRoutes;
