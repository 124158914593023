import axios from 'axios';
import { isNil, omitBy } from 'lodash';
import {
  WarehouseFloor,
  WarehouseFloorCreatePayload,
  WarehousePalette,
  WarehousePaletteCreateParams,
  WarehousePaletteParams,
  WarehouseZone,
} from 'src/types/warehouse';
import { API_URL } from './api';

class WarehouseAPI {
  async getListWarehouseFloor(filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
  }): Promise<{ warehouseFloor?: WarehouseFloor[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(filter, isNil)).toString();
    const response = await axios.get(`${API_URL.WAREHOUSE_FLOOR}?${query}`);
    if (response?.status === 200) {
      const { warehouseFloor, total } = response?.data?.data?.attributes;
      return { warehouseFloor, total, success: true };
    }
    return null;
  }

  async getListWarehouseZoneByFloorId(filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
    floorIds: string;
  }): Promise<{ warehouseZone?: WarehouseZone[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(filter, isNil)).toString();
    const response = await axios.get(`${API_URL.WAREHOUSE_ZONE}?${query}`);
    if (response?.status === 200) {
      const { warehouseZone, total } = response?.data?.data?.attributes;
      return { warehouseZone, total, success: true };
    }
    return null;
  }

  async getListWarehousePaletteByFloorId(
    filter?: WarehousePaletteParams,
  ): Promise<{ warehousePalette?: WarehousePalette[]; total?: number; success: boolean }> {
    const query = new URLSearchParams(omitBy(filter, isNil)).toString();
    const response = await axios.get(`${API_URL.WAREHOUSE_PALETTE}?${query}`);
    if (response?.status === 200) {
      const { warehousePallette, total } = response?.data?.data?.attributes;
      return { warehousePalette: warehousePallette, total, success: true };
    }
    return null;
  }

  async getWarehousePalettesForProrductReceipt(
    productId: string,
  ): Promise<{ warehousePalette?: WarehousePalette[]; success: boolean }> {
    const query = new URLSearchParams({
      productId,
    }).toString();

    const response = await axios.get(`${API_URL.WAREHOUSE_PALETTE}/pallette-for-product-receipt?${query}`);

    if (response?.status === 200) {
      const { warehousePalletteProduct } = response?.data?.data?.attributes;
      return { warehousePalette: warehousePalletteProduct, success: true };
    }
    return null;
  }

  async createFloor(
    payload: WarehouseFloorCreatePayload,
  ): Promise<{ data?: WarehouseFloor; success: boolean; message?: string }> {
    try {
      const response = await axios.post(`${API_URL.WAREHOUSE_FLOOR}`, payload);

      return { success: true, data: response.data?.data?.attributes };
    } catch (err) {
      console.error('[Warehouse Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async updateFloor(
    id: string,
    payload: WarehouseFloorCreatePayload,
  ): Promise<{ data?: WarehouseFloor; success: boolean; message?: string }> {
    try {
      const response = await axios.patch(`${API_URL.WAREHOUSE_FLOOR}/${id}`, payload);
      return {
        success: true,
        data: {
          ...response.data?.data?.attributes,
          id: response?.data?.data?.id,
        },
      };
    } catch (err) {
      console.error('[Order Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async getFloorById(id: string): Promise<{ data?: WarehouseFloor; success: boolean; message?: string }> {
    try {
      const response = await axios.get(`${API_URL.WAREHOUSE_FLOOR}/${id}`);

      return { success: true, data: { ...response.data?.data?.attributes, id } };
    } catch (err) {
      console.error('err ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async deletePaletteById(id: string): Promise<{ data?: WarehousePalette; success: boolean; message?: string }> {
    try {
      const response = await axios.delete(`${API_URL.WAREHOUSE_PALETTE}/${id}`);

      if (response?.data?.data) {
        return { success: true };
      }
      return { success: false };
    } catch (err) {
      console.error('err ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async getPaletteById(id: string): Promise<{ data?: any; success: boolean; message?: string }> {
    try {
      const response = await axios.get(`${API_URL.WAREHOUSE_PALETTE}/${id}`);

      return { success: true, data: { ...response.data?.data?.attributes, id } };
    } catch (err) {
      console.error('err ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async createPalette(
    payload: WarehousePaletteCreateParams,
  ): Promise<{ data?: WarehousePalette; success: boolean; message?: string }> {
    try {
      const response = await axios.post(`${API_URL.WAREHOUSE_PALETTE}`, payload);

      return { success: true, data: response.data?.data?.attributes };
    } catch (err) {
      console.error('[Warehouse Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }

  async updatePalette(
    id: string,
    payload: WarehousePaletteCreateParams,
  ): Promise<{ data?: WarehousePalette; success: boolean; message?: string }> {
    try {
      const response = await axios.patch(`${API_URL.WAREHOUSE_PALETTE}/${id}`, payload);

      return { success: true, data: response.data?.data?.attributes };
    } catch (err) {
      console.error('[Warehouse Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
  }
}

export const warehouseAPI = new WarehouseAPI();
