import axios from 'axios';
import { omitBy, isEmpty, isBoolean } from 'lodash';
import { ProductCUFormValues } from 'src/components/product/CUForm';

import { DEFAULT_PAGESIZE } from 'src/constants';

import { IPagination, ResponseDataPaging } from 'src/types/data';
import { ProductReceiptV2, ProductV2, ProductV2Overview, ProductV2Params } from 'src/types/product';
// eslint-disable-next-line import/namespace
import { API_URL } from './api';

class ProductV2API {
  async getList(
    params?: ProductV2Params,
    pagination?: Omit<IPagination, 'count'>,
  ): Promise<ResponseDataPaging<ProductV2[]> | null> {
    let query = new URLSearchParams(omitBy(params, isEmpty)).toString();

    if (isBoolean(params.outOfStock)) {
      query += `&outOfStock=${params.outOfStock.toString()}`;
    }

    const response = await axios.get(
      `${API_URL.PRODUCT_V2}?${query}&limit=${pagination.rowsPerPage || DEFAULT_PAGESIZE}&offset=${
        pagination.rowsPerPage * pagination.page || 0
      }`,
    );
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async getDataOverView(): Promise<{ data?: ProductV2Overview; success: boolean }> {
    const response = await axios.get(`${API_URL.PRODUCT_V2}/overview`);

    if (response?.data) {
      const overview = response?.data;
      return { data: overview, success: true };
    }
    return null;
  }

  async getProductV2ListByReceipt(
    params?: ProductV2Params,
    pagination?: Omit<IPagination, 'count'>,
  ): Promise<ResponseDataPaging<ProductV2[]> | null> {
    let query = new URLSearchParams(omitBy(params, isEmpty)).toString();
    if (isBoolean(params.outOfStock)) {
      query += `&outOfStock=${params.outOfStock.toString()}`;
    }

    const response = await axios.get(
      `${API_URL.PRODUCT_RECEIPT}/product?${query}&limit=${pagination.rowsPerPage || DEFAULT_PAGESIZE}&offset=${
        pagination.rowsPerPage * pagination.page || 0
      }`,
    );
    if (response?.status === 200) {
      const productData = response.data.data.attributes;
      const { product, ...pagingData } = productData;
      return {
        data: product,
        paging: pagingData,
      };
    }
    return null;
  }

  async getManufacturer(): Promise<ResponseDataPaging<string[]> | null> {
    const response = await axios.get(`${API_URL.PRODUCT}/manufacturer`);
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async getOriginCountry(): Promise<ResponseDataPaging<string[]> | null> {
    const response = await axios.get(`${API_URL.PRODUCT}/origin-country`);
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async deleteById(id: string): Promise<{ success: boolean }> {
    const response = await axios.delete(`${API_URL.PRODUCT}/${id}`);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }

  async post(params: ProductCUFormValues): Promise<{ success: boolean }> {
    try {
      const response = await axios.post(`${API_URL.PRODUCT}`, params);
      if (response?.status === 200) {
        return { success: true };
      }
    } catch (err) {
      console.error(err);
      return { success: false };
    }
    return { success: false };
  }

  async postProductReceiptV2(params: ProductReceiptV2): Promise<{ success: boolean }> {
    try {
      const response = await axios.post(`${API_URL.PRODUCT_RECEIPT}`, params);
      if (response?.status === 200) {
        return { success: true };
      }
    } catch (err) {
      console.error(err);
      return { success: false };
    }
    return { success: false };
  }

  async patch(id: string, params: ProductCUFormValues): Promise<{ success: boolean }> {
    const response = await axios.patch(`${API_URL.PRODUCT}/${id}`, params);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }

  async check(name: string, value: string): Promise<{ success: boolean }> {
    const response = await axios.get(`${API_URL.SEARCH}?name=${name}&value=${value}`);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }

  async importExcel(body: { file: File }): Promise<{ success: boolean }> {
    const response = await axios.post(`${API_URL.IMPORT}/product`, body, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }
}

export const productV2API = new ProductV2API();
