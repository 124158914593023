/* eslint-disable prettier/prettier */
import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as chatReducer } from '../slices/chat';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as mailReducer } from '../slices/mail';
import { reducer as userReducer } from '../slices/user';
import { reducer as customersReducer } from '../slices/customers';
import { reducer as megaCateReducer } from '../slices/megaCategory';
import { reducer as appReducer } from '../slices/app';
import { reducer as subCateReducer } from '../slices/subCategory';
import { reducer as productReducer } from '../slices/product';
import { reducer as dialogReducer } from '../slices/dialog';
import { reducer as categoryReducer } from '../slices/category';
import { reducer as advertiserReducer } from '../slices/advertiser';
import { reducer as tagReducer } from '../slices/tag';
import { reducer as saleReducer } from '../slices/sale';
import { reducer as recipeReducer } from 'src/slices/recipe';
import { reducer as orderReducer } from 'src/slices/order';
import { reducer as postcodeReducer } from 'src/slices/postcode';
import { reducer as storeReducer } from 'src/slices/store';
import { reducer as brandReducer } from 'src/slices/brand';
import { reducer as brandCategoryReducer } from 'src/slices/brandCategory';
import { reducer as catalogReducer } from 'src/slices/catalog';
import { reducer as ordersV2Reducer } from 'src/slices/orders-v2';
import { reducer as supplierReducer } from 'src/slices/supplier';
import { reducer as packagingUnitReducer } from 'src/slices/packagingUnit';
import { reducer as warehourseReducer } from 'src/slices/warehouses';
import { reducer as warehourseZonesReducer } from 'src/slices/warehouse-zones';
import { reducer as warehoursePaletteReducer } from 'src/slices/warehouse-palette';
import { reducer as jobReducer } from 'src/slices/job';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as annotationsReducer } from 'src/slices/annotations';
import { reducer as productV2Reducer } from '../slices/productV2';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  user: userReducer,
  customers: customersReducer,
  megaCategory: megaCateReducer,
  app: appReducer,
  subCate: subCateReducer,
  dialog: dialogReducer,
  category: categoryReducer,
  advertiser: advertiserReducer,
  product: productReducer,
  productV2: productV2Reducer,
  tag: tagReducer,
  sale: saleReducer,
  recipe: recipeReducer,
  order: orderReducer,
  postcode: postcodeReducer,
  store: storeReducer,
  brand: brandReducer,
  brandCategory: brandCategoryReducer,
  catalog: catalogReducer,
  orderV2: ordersV2Reducer,
  supplier: supplierReducer,
  packagingUnit: packagingUnitReducer,
  warehouse: warehourseReducer,
  warehouseZones: warehourseZonesReducer,
  warehoursePalette: warehoursePaletteReducer,
  job: jobReducer,
  notification: notificationReducer,
  annotations: annotationsReducer,
});

export default rootReducer;
