import axios from 'axios';
import { isNil, omitBy, isEmpty, isBoolean } from 'lodash';
import { ProductCUFormValues } from 'src/components/product/CUForm';

import { DEFAULT_PAGESIZE } from 'src/constants';

import { IPagination, ResponseDataPaging } from 'src/types/data';
import { Product, ProductParams } from 'src/types/product';
// eslint-disable-next-line import/namespace
import { API_URL } from './api';

class ProductAPI {
  async searchProduct(params?: ProductParams, pagination?: Omit<IPagination, 'count'>): Promise<Product[]> {
    let query = new URLSearchParams(omitBy(params, isEmpty)).toString();
    if (pagination?.rowsPerPage) {
      query += `&limit=${pagination.rowsPerPage}&offset=${pagination.rowsPerPage * pagination.page || 0}`;
    }

    const response = await axios.get(`${API_URL.PRODUCT_V2}/admin-search/?${query}`);
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async getList(
    params?: ProductParams,
    pagination?: Omit<IPagination, 'count'>,
  ): Promise<ResponseDataPaging<Product[]> | null> {
    let query = new URLSearchParams(omitBy(params, isEmpty)).toString();
    if (isBoolean(params.outOfStock)) {
      query += `&outOfStock=${params.outOfStock.toString()}`;
    }

    const response = await axios.get(
      `${API_URL.PRODUCT}?${query}&limit=${pagination.rowsPerPage || DEFAULT_PAGESIZE}&offset=${
        pagination.rowsPerPage * pagination.page || 0
      }`,
    );
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async getProductListByReceipt(
    params?: ProductParams,
    pagination?: Omit<IPagination, 'count'>,
  ): Promise<ResponseDataPaging<Product[]> | null> {
    let query = new URLSearchParams(omitBy(params, isEmpty)).toString();
    if (isBoolean(params.outOfStock)) {
      query += `&outOfStock=${params.outOfStock.toString()}`;
    }

    const response = await axios.get(
      `${API_URL.PRODUCT_RECEIPT}/product?${query}&limit=${pagination.rowsPerPage || DEFAULT_PAGESIZE}&offset=${
        pagination.rowsPerPage * pagination.page || 0
      }`,
    );
    if (response?.status === 200) {
      const productData = response.data.data.attributes;
      const { product, ...pagingData } = productData;
      return {
        data: product,
        paging: pagingData,
      };
    }
    return null;
  }

  async getManufacturer(): Promise<ResponseDataPaging<string[]> | null> {
    const response = await axios.get(`${API_URL.PRODUCT}/manufacturer`);
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async getOriginCountry(): Promise<ResponseDataPaging<string[]> | null> {
    const response = await axios.get(`${API_URL.PRODUCT}/origin-country`);
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async deleteById(id: string): Promise<{ success: boolean }> {
    const response = await axios.delete(`${API_URL.PRODUCT}/${id}`);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }

  async post(params: ProductCUFormValues): Promise<{ success: boolean }> {
    try {
      const response = await axios.post(`${API_URL.PRODUCT}`, params);
      if (response?.status === 200) {
        return { success: true };
      }
    } catch (err) {
      console.error(err);
      return { success: false };
    }
    return { success: false };
  }

  async patch(id: string, params: ProductCUFormValues): Promise<{ success: boolean }> {
    const response = await axios.patch(`${API_URL.PRODUCT}/${id}`, params);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }

  async check(name: string, value: string): Promise<{ success: boolean }> {
    const response = await axios.get(`${API_URL.SEARCH}?name=${name}&value=${value}`);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }

  async importExcel(body: { file: File }): Promise<{ success: boolean }> {
    const response = await axios.post(`${API_URL.IMPORT}/product`, body, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }
}

export const productAPI = new ProductAPI();
