import { ADMIN, SUPER_ADMIN } from 'src/constants';

export const ROLES_BY_ROUTE = {
  '/management/customers': [ADMIN, SUPER_ADMIN],
  '/management/customers/new': [ADMIN, SUPER_ADMIN],
  '/management/customers/:customerId/edit': [ADMIN, SUPER_ADMIN],
  '/management/customers/:customerId/detail': [ADMIN, SUPER_ADMIN],
  '/management/users': [ADMIN, SUPER_ADMIN],
  '/management/users/new': [ADMIN, SUPER_ADMIN],
  '/management/users/:userId/edit': [ADMIN, SUPER_ADMIN],
  '/management/users/:userId/detail': [ADMIN, SUPER_ADMIN],
  '/orders': [ADMIN, SUPER_ADMIN],
  '/orders/new': [ADMIN, SUPER_ADMIN],
  '/orders/:orderId/detail': [ADMIN, SUPER_ADMIN],
  '/orders/:orderId/edit': [ADMIN, SUPER_ADMIN],
  '/warehouse-floor': [ADMIN, SUPER_ADMIN],
  '/warehouse-floor/new': [ADMIN, SUPER_ADMIN],
  '/warehouse-floor/:warehouseFloorId/detail': [ADMIN, SUPER_ADMIN],
  '/warehouse-floor/:warehouseFloorId/edit': [ADMIN, SUPER_ADMIN],
  '/warehouse-floor/:warehouseFloorId/warehouse-palette/new': [ADMIN, SUPER_ADMIN],
  '/warehouse-floor/:warehouseFloorId/warehouse-palette/:paletteId/detail': [ADMIN, SUPER_ADMIN],
  '/warehouse-floor/:warehouseFloorId/warehouse-palette/:paletteId/edit': [ADMIN, SUPER_ADMIN],
};
