import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { warehouseAPI } from 'src/api/warehouseAPI';
import { DEFAULT_PAGESIZE, INIT_PAGINATION } from 'src/constants';

import { AppThunk } from 'src/store';
import { DataPaging, IPagination } from 'src/types/data';
import { WarehouseZone, WarehouseZoneFilterValues, WarehouseZoneParams } from 'src/types/warehouse';

export type WarehouseZoneState = {
  data: DataPaging<WarehouseZone[]>;
  params: WarehouseZoneParams;
  filter: WarehouseZoneFilterValues;
};

export const initialState: WarehouseZoneState = {
  data: {
    data: [],
    pagination: INIT_PAGINATION,
    mapping: {},
  },
  params: {},
  filter: {},
};

const warehouseZonesSlice = createSlice({
  name: 'warehouse-zones',
  initialState,
  reducers: {
    setDataWarehouseZone(
      state,
      action: PayloadAction<{ data: DataPaging<WarehouseZone[]> | null; params: WarehouseZoneParams; filter: any }>,
    ) {
      state.data = action.payload.data;
      state.filter = action.payload.filter;
      state.params = action.payload.params;
    },
    resetDataWarehouseZone(state) {
      state.data = initialState.data;
      state.params = initialState.params;
      state.filter = initialState.filter;
    },
  },
});
export const {
  reducer,
  actions: { setDataWarehouseZone, resetDataWarehouseZone },
} = warehouseZonesSlice;

export default warehouseZonesSlice;

export const getListWarehouseZone =
  ({
    filter,
    pagination,
    params,
  }: {
    filter?: WarehouseZoneFilterValues;
    pagination: Omit<IPagination, 'count'>;
    params: WarehouseZoneParams;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const filterValues = {
      limit: pagination.rowsPerPage || DEFAULT_PAGESIZE,
      offset: pagination.rowsPerPage * pagination.page || 0,
      searchValue: params.searchValue,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      status: params.status,
      id: params.id,
      palletteId: params.palletteId,
      floorId: params.floorId,
    };
    const data = await warehouseAPI.getListWarehouseZoneByFloorId(filterValues as any);

    if (data) {
      dispatch(
        setDataWarehouseZone({
          data: {
            data: data.warehouseZone,
            pagination: {
              ...pagination,
              count: data.total,
            },
          },
          filter,
          params,
        }),
      );
    }
  };
