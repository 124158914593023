export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  federationTarget: 'COGNITO_USER_POOLS',
  oauth: {
    domain: process.env.REACT_APP_AWS_DOMAIN,
    scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_AWS_SIGN_IN_URL,
    redirectSignOut: process.env.REACT_APP_AWS_SIGN_OUT_URL,
    responseType: 'code',
  },
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
  vapiKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

export const mapyConfig = {
  apiKey: process.env.REACT_APP_MAPY_API_KEY,
  apiURL: process.env.REACT_APP_MAPY_API_URL,
};

export const featureFlagConfig = {
  warehouses: process.env.REACT_APP_WAREHOUSES_FEATURE_FLAG === 'true',
  productsV2: process.env.REACT_APP_PRODUCT_V2_FEATURE_FLAG === 'true',
};
