import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { productV2API } from 'src/api/productV2API';

import { INIT_PAGINATION } from 'src/constants';

import { AppThunk } from 'src/store';
import { DataPaging, IPagination, ResponseDataPaging } from 'src/types/data';
import {
  ProductReceiptV2,
  ProductV2,
  ProductV2FilterValues,
  ProductV2Overview,
  ProductV2Params,
} from 'src/types/product';

export type ProductV2State = {
  data: DataPaging<ProductV2[]>;
  params: ProductV2Params;
  filter: ProductV2FilterValues;
  productPaletteData: DataPaging<ProductV2[]>;
  productPaletteParams: ProductV2Params;
  productPaletteFilter: ProductV2FilterValues;
  overviewData: ProductV2Overview;
};

export const productV2InitialState: ProductV2State = {
  data: {
    data: [],
    pagination: INIT_PAGINATION,
    mapping: {},
  },
  params: {},
  filter: {},
  productPaletteData: {
    data: [],
    pagination: INIT_PAGINATION,
    mapping: {},
  },
  productPaletteParams: {},
  productPaletteFilter: {},
  overviewData: {
    inStock: '0',
    outOfStock: '0',
    total: '0',
  },
};

const productSlice = createSlice({
  name: 'product',
  initialState: productV2InitialState,
  reducers: {
    setDataProductV2(
      state,
      action: PayloadAction<{ data: DataPaging<ProductV2[]> | null; params: ProductV2Params; filter: any }>,
    ) {
      state.data = action.payload.data;
      state.filter = action.payload.filter;
      state.params = action.payload.params;
    },
    resetDataProductV2(state) {
      state.data = productV2InitialState.data;
      state.params = productV2InitialState.params;
      state.filter = productV2InitialState.filter;
    },

    setProductV2OverviewData(state, action: PayloadAction<ProductV2Overview>) {
      state.overviewData = action.payload;
    },
    setDataProductV2Palette(
      state,
      action: PayloadAction<{ data: DataPaging<ProductV2[]> | null; params: ProductV2Params; filter: any }>,
    ) {
      state.productPaletteData = action.payload.data;
      state.productPaletteFilter = action.payload.filter;
      state.productPaletteParams = action.payload.params;
    },
    resetDataProductV2Palette(state) {
      state.productPaletteData = productV2InitialState.productPaletteData;
      state.productPaletteParams = productV2InitialState.productPaletteParams;
      state.productPaletteFilter = productV2InitialState.productPaletteFilter;
    },
  },
});
export const {
  reducer,
  actions: {
    setDataProductV2,
    resetDataProductV2,
    setDataProductV2Palette,
    resetDataProductV2Palette,
    setProductV2OverviewData,
  },
} = productSlice;

export default productSlice;

export const getListProductV2 =
  ({
    filter,
    pagination,
    params,
  }: {
    filter?: ProductV2FilterValues;
    pagination: Omit<IPagination, 'count'>;
    params: ProductV2Params;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await productV2API.getList(params, pagination);

    if (data) {
      dispatch(
        setDataProductV2({
          data: {
            ...data,
            pagination: {
              ...pagination,
              count: data.paging.total,
            },
          },
          filter,
          params,
        }),
      );
    }
  };

export const getListProductV2Palette =
  ({
    filter,
    pagination,
    params,
  }: {
    filter?: ProductV2FilterValues;
    pagination: Omit<IPagination, 'count'>;
    params: ProductV2Params;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await productV2API.getProductV2ListByReceipt(params, pagination);

    if (data) {
      dispatch(
        setDataProductV2Palette({
          data: {
            ...data,
            pagination: {
              ...pagination,
              count: data.paging.total,
            },
          },
          filter,
          params,
        }),
      );
    }
  };

export const getProductV2OverviewData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await productV2API.getDataOverView();
    if (data?.success) {
      dispatch(setProductV2OverviewData(data.data));
    }
  };

export const deleteProductV2ById =
  (id: string, callback?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await productV2API.deleteById(id);
    if (data?.success) {
      callback?.();
    }
  };

// export const updateProductV2 =
//   (id: string, params: ProductV2CUFormValues, callback?: () => void): AppThunk =>
//   async (): Promise<void> => {
//     const data = await productV2API.patch(id, params);
//     if (data?.success) {
//       callback?.();
//     }
//   };

export const checkProductV2 =
  (name: string, value: string, callback?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await productV2API.check(name, value);
    if (data?.success) {
      callback?.();
    }
  };

export const importExcel =
  (body: { file: File }, onSuccess?: () => void, onFailure?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await productV2API.importExcel(body);
    if (data?.success) {
      onSuccess?.();
      return;
    }
    onFailure?.();
  };

export const getManufacturer = (): Promise<ResponseDataPaging<string[]>> => productV2API.getManufacturer();

export const getOriginCountry = (): Promise<ResponseDataPaging<string[]>> => productV2API.getOriginCountry();

export const createProductReceiptV2 =
  (params: ProductReceiptV2, callback?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await productV2API.postProductReceiptV2(params);

    if (data?.success) {
      callback?.();
    }
  };
