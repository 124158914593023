export const API_URL = {
  MEGA_CATEGORY: '/api/mega-category',
  SUB_CATEGORY: '/api/sub-category',
  CATEGORY: '/api/category',
  FILES: '/api/files',
  PRODUCT: '/api/product',
  PRODUCT_V2: '/api/v2/product',
  PRODUCT_RECEIPT: '/api/product-receipt',

  ADVERTISER: '/api/advertiser',
  TAG: '/api/tag',
  SALE: '/api/sale',
  RECIPE: '/api/recipe',
  ORDER: '/api/orders',
  POSTCODE: '/api/postcode',
  OVERVIEW: '/api/overview',
  REPORT: '/api/report',
  STORE: '/api/store',
  SEARCH: '/api/search',
  BRAND: '/api/brand',
  BRAND_CATEGORY: '/api/brand-category',
  CATALOG: '/api/catalog',
  USER: '/api/users',
  SUPPLIER: '/api/suppliers',
  PACKAGING_UNIT: '/api/packaging-unit',
  WAREHOUSE_FLOOR: '/api/warehouse-floor',
  WAREHOUSE_ZONE: '/api/warehouse-zone',
  WAREHOUSE_PALETTE: '/api/warehouse-pallette',
  IMPORT: '/api/import',
  JOB: '/api/jobs',
};
