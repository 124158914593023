import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { warehouseAPI } from 'src/api/warehouseAPI';
import { DEFAULT_PAGESIZE, INIT_PAGINATION } from 'src/constants';
import { AppThunk } from 'src/store';
import { DataPaging, IPagination } from 'src/types/data';
import {
  WarehousePalette,
  WarehousePaletteCreateParams,
  WarehousePaletteParams,
  WarehousePalleteFilterValues,
} from 'src/types/warehouse';

export type WarehousePaletteState = {
  currentPalette: WarehousePalette | null;
  data: DataPaging<WarehousePalette[]>;
  params: WarehousePaletteParams;
  filter: WarehousePalleteFilterValues;
};

export const initialState: WarehousePaletteState = {
  currentPalette: null,
  data: {
    data: [],
    pagination: INIT_PAGINATION,
    mapping: {},
  },
  params: {},
  filter: {},
};

const warehousePalettesSlice = createSlice({
  name: 'warehouse-palettes',
  initialState,
  reducers: {
    setDataWarehousePalette(
      state,
      action: PayloadAction<{
        data: DataPaging<WarehousePalette[]> | null;
        params: WarehousePaletteParams;
        filter: any;
      }>,
    ) {
      state.data = action.payload.data;
      state.filter = action.payload.filter;
      state.params = action.payload.params;
    },
    resetDataWarehousePalette(state) {
      state.data = initialState.data;
      state.params = initialState.params;
      state.filter = initialState.filter;
    },
    setCurrentPalette(state, action: PayloadAction<WarehousePalette>) {
      state.currentPalette = action.payload;
    },
  },
});
export const {
  reducer,
  actions: { setCurrentPalette, setDataWarehousePalette, resetDataWarehousePalette },
} = warehousePalettesSlice;

export const getListWarehousePalette =
  ({
    filter,
    pagination,
    params,
  }: {
    filter?: WarehousePalleteFilterValues;
    pagination: Omit<IPagination, 'count'>;
    params: WarehousePaletteParams;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const filterValues = {
      limit: pagination.rowsPerPage || DEFAULT_PAGESIZE,
      offset: pagination.rowsPerPage * pagination.page || 0,
      searchValue: params.searchValue,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      status: params.status,
      zoneId: params.zoneId,
      palletteId: params.palletteId,
      floorId: params.floorId,
    };
    const data = await warehouseAPI.getListWarehousePaletteByFloorId(filterValues as any);

    if (data) {
      dispatch(
        setDataWarehousePalette({
          data: {
            data: data.warehousePalette,
            pagination: {
              ...pagination,
              count: data.total,
            },
          },
          filter,
          params,
        }),
      );
    }
  };

export const createWarehousePalette =
  ({
    values,
    onSuccess,
    onError,
  }: {
    values: WarehousePaletteCreateParams;
    onSuccess?: (res: WarehousePalette) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await warehouseAPI.createPalette(values);

      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const updateWarehousePalette =
  ({
    id,
    values,
    onSuccess,
    onError,
  }: {
    id: string;
    values: WarehousePaletteCreateParams;
    onSuccess?: (res: WarehousePalette) => void;
    onError?: () => void;
  }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await warehouseAPI.updatePalette(id, values);

      if (response.success) {
        onSuccess?.(response.data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };

export const getWarehousePaletteById =
  (id: string): AppThunk =>
  async (disptach): Promise<any> => {
    try {
      const response = await warehouseAPI.getPaletteById(id);

      const { data } = response;

      if (response.success) {
        disptach(
          setCurrentPalette({
            ...data,
            // as this is an warehouse-product item, we will need to tranform
            // it to a product item to fill the data to product list +
            // product modal when creating/updating the palette
            products: data.products?.map((item) => ({
              code: item.code,
              ean: item.ean,
              expiredDate: item.expiredDate,
              id: item.productId,
              image: item.image,
              name: item.name,
              packagingUnitsId: item.packagingUnitsId,
              packagingUnitsName: item.packagingUnitsName,
              productReceiptId: item.receiptId,
              supplierId: item.supplierId,
              supplierName: item.supplierName,
              unitPrice: item.unitPrice,
              quantity: item.rootQuantity,
              paletteQuantity: item.quantity,
              warehouseProductId: item.id,
            })),
          }),
        );
      }
    } catch (error) {
      console.log('error', error);
    }
  };

export const deletePaletteById =
  ({ onSuccess, onError, id }: { id: string; onSuccess?: () => void; onError?: () => void }): AppThunk =>
  async (): Promise<any> => {
    try {
      const response = await warehouseAPI.deletePaletteById(id);

      if (response.success) {
        onSuccess?.();
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.();
    }
  };
